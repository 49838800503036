@import "../../assets/css/Components.scss";
.slider-full-width{
    display: block;
    width:100%;
    height:calc(100vh - 100px);
    img{
        display:block;
        width:100%;
        height:100%;
        object-fit: cover;
    }
}