@import "../../assets/css/Components.scss";
.product-list-page{
    .breadcrumb{
        margin-bottom:0;
        padding:15px 0;
    }
}
.category-image-container{
    display:block;
    width:100%;
    height:450px;
    .category-main-image{
        display: block;
        width:100%;
    }    
}
.product-container{
    @extend .d-flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-start;
    .filter-sidebar{
        max-width:350px;
        width:100%;
        height:100%;
    }
    .product-list{
        width:calc(100% - 380px);
        .product-image{
            display: block;
            width:100%;
        }
    }
    .mobile-filter{
        @extend .d-flex;
        justify-content: space-between;
        margin-bottom:1.4rem;
        .total{
            font-size: 1rem;
            color:$gray-700;
        }
        .icon-set{
            .fa{
                font-size:1rem;
                color:$gray-700;
            }
        }
    }
    .list-image-container{
        padding:1rem;
        border:1px solid $gray-300;
        .enquiry-fotm-btn{
            width:100%;
            height:46px;
            @extend .d-flex;
            background-color: $primary;
            color:$white;
            text-transform: uppercase;
            letter-spacing: 1.25px;
            font-size:1rem;
            border-radius: .4rem;
            margin-top:.6rem;
            cursor: pointer;
        }
    }
    .product-filter-container{
        padding:1rem 0;
        .title{
            font-size: 1.4rem;
            text-transform: uppercase;
            letter-spacing: 3.5px;
            margin-bottom:1rem;
            font-weight: 600;
        }
        .filter-list{
            @extend .d-flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left:1.4rem;
            .filter-nav{
                font-size:1rem;
                padding:.4rem 0;
                &.active{
                    color:$primary;
                }
                +.filter-nav{
                    margin-top:.4rem;
                }
            }
        }
    }
    
}