@import "../../assets/css/Components.scss";
.backdrop{
    display: block;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,.3);
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 10;
}
.popup-sidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    right:-100%;
    width:560px;
    height:100%;
    background-color: #fff;
    transition:all .5s ease-in-out;
    z-index: 20;
    padding:100px 20px;
    &.active{
        right:0
    }
    .menu-close{
        @extend .d-flex;
        position: absolute;
        top:0;
        right:0;
        background-color: $primary;
        width: 110px;
        height: 100px;
        .icon-set{
            border: 0;
            padding: 0;
            background-color: transparent;
            position: relative;
            display: inline-block;
            width: 25px;
            height: 25px;
            border-radius: 30px;
            cursor: pointer;
            span {
                display: block;
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background-color:$white;
                transition: all .3s ease;
                opacity: 1;                
                &:nth-of-type(1) {
                  top: 6px;
                }
                &:nth-of-type(2) {
                  top: 12px;
                }
                &:nth-of-type(3) {
                  top: 18px;
                }
            }
            &:hover {
                span {
                  &:nth-of-type(1),
                  &:nth-of-type(3) {
                    top: 12px;
                  }
                  &:nth-of-type(1) {
                    transform: rotate(45deg);
                  }
                  &:nth-of-type(2) {
                    opacity: 0;
                  }
                  &:nth-of-type(3) {
                    transform: rotate(-45deg);
                  }
                }
            } 
        }
    }
    .sidebar-content{
        padding:35px 20px;
        height:100%;
        @extend .d-flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: flex-start;
        .sidebar-heading{
            @extend .d-flex;
            justify-content: flex-start;
            margin-bottom:2rem;
            img{
                width:50px;
                margin-right:1rem;
            }
            h3{
                text-transform: uppercase;
                margin:0;
                padding:0;
                font-size:2.5rem;
                letter-spacing: 3px;
            }
        }
        .service-box{
            margin-bottom:2rem;
            ul{
                @extend .d-flex;
                justify-content: space-between;
                width:100%;
                list-style: none;
                margin:0;
                padding:0;
                flex-wrap: wrap;
                gap:15px;
                li{
                    width:calc(50% - 15px);
                    text-transform: uppercase;
                    font-size:.75rem;
                    letter-spacing: 1.5px;
                }
            }
        }
        .contact-info{
            h3{
                text-transform: uppercase;
                margin:0;
                padding:0;
                font-size:2.5rem;
                letter-spacing: 3px;
            }
            ul{
                margin: 1.4rem 0 0 0;
                padding:0;
                list-style-type: none;
                li{
                    font-size: 1.2rem;
                    margin-bottom:.6rem;
                    @extend .d-flex;
                    justify-content: flex-start;
                    .fa{
                        font-size:1.5rem;
                        margin-right:1rem;
                        color:$gray-600;
                    }
                }
            }
        }
        .contact-btn{
            width:180px;
            height:46px;
            @extend .d-flex;
            background-color: $primary;
            color:$white;
            font-size:1rem;
            text-transform: uppercase;
            margin-top:2rem;
            border-radius: .4rem;
            cursor: pointer;
            letter-spacing: 1.25px;
        }
    }
    &.mobile{       
        left:-100%;
        width:70%;
        height:100%;
        z-index: 20;
        padding:5vw;
        &.active{
            left:0;
        }
        .menu-close{
            width:56px;
            height:56px;
            background-color: transparent;
            .icon-set{
                display: flex;
            }
        }
        .sidebar-content{
            justify-content: flex-start;
            padding:50px 0 0;
            .nav-center{
                margin:0;
                padding:0;
                @extend .d-flex;
                flex-direction: column;
                align-items: flex-start;
                .mobile-nav{
                    font-size:2vw;
                    @media(max-width:768px){
                        font-size:4vw;
                    }
                    text-transform: uppercase;
                    +.mobile-nav{
                        margin-top:.5rem;
                    }
                }
            }
            .sidebar-heading{
                margin:1rem 0;
                h3{
                    font-size:4vw;
                    @media(max-width:768px){
                        font-size:5vw;
                    }
                    color:$primary;
                }
            }
            .service-box{   
                margin:0;            
                ul{
                    display: block;
                    padding-left:3vw;
                    a{
                        font-size:2vw;
                        @media(max-width:768px){
                            font-size:4vw;
                        }
                        display: block;                       
                        text-transform: uppercase;
                        +a{
                            margin-top:.5rem;
                        }
                    }
                }
            }
        }
    }
}
