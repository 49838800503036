@import "../../assets/css/Components.scss";

.section-compare-slider {
    width: 100%;
    @extend .d-flex;
    justify-content: space-between;
    @media(max-width:920px){
       display:block;
    }
    .sliderControl{
        width:100%;
        height:100%;
    }
    .compareBox{
        width:calc(50% - 15px);
        position: relative;
        @media(max-width:920px){
            width:100%;
            +.compareBox{
                margin-top:20px;
            }
        }
        .title{
            position:absolute;
            top:35px;
            left:25px;
            z-index: 2;
            font-size:2rem;
            text-transform: uppercase;
            letter-spacing: 1.25px;
            font-weight: 500;
            line-height: 1.3;
            @media(max-width:1368px){
                font-size:1.5rem;
            }
            @media(max-width:540px){
                font-size:3vw;
            }  
            span{
                display: block;
            }
            &:before{
                content:"";
                width:40%;
                height:2px;
                background-color: $primary;
                left:0;
                bottom:-8px;
                position:absolute;
                z-index: 2;
            }
        }
    }
}