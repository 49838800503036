@import "./Colors.scss";
$fredoka:"Fredoka", serif;

body {
  margin: 0;
  font-family: $fredoka;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: $fredoka;
    overflow-x: hidden;    
}
a{
    text-decoration: none;
    color:$black;
}

//Screen sizes
$laptop:"1368px";
$ipad:"1180px";
$mobile-large:"912px";
$mobile:"412px";

.img{
    display: block;
    width:100%;
    height:100%;
    object-fit: cover;
}
.d-flex{
    display:flex;
    justify-content: center;
    align-items: center;
}
.mt-50{
    margin-top:3.25rem;
}
.mx-1450{
    max-width: 1450px;
    width:100%;
    display: block;
}
.border{
    border:1px solid $gray-500;
}
//typo
.text-4xl {   
    font-size: 2.25rem;
}
.text-3xl {   
    font-size: 1.875rem;
}
.text-xxl {
    font-size: 1.5rem;
}
.text-xl {
    font-size: 1.25rem;
}
.text-lg {
    font-size: 1.125rem;
}
.text-sm{
    font-size: .875rem;
}
.text-xs {
    font-size: .75rem;
}
.text-base{
    font-size: 1rem;
}
.text-body{
    font-size: 15px;
}
.leading-none {
    line-height: 1;
}
.label{
    font-family:$fredoka;
    font-size: 11px; 
    line-height: 1.3; 
}
.font-bold {
    font-weight: 700;
}
.font-semibold {
    font-weight: 600;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}

.widget-title {
    h3 {
        font-family: $fredoka;
    }
}
.icon-set{
    width:48px;
    height:48px;  
    position:relative;  
    cursor:pointer;
    @extend .d-flex;
    .badge{
     position:absolute;
     top:0;
     right:0;
     font-size:10px;
     background-color: $danger;
    }
    .icon{
     font-size:18px;
    }
    +.icon-set{
     margin-left:4px;
    }
    .r-icon{
     &:hover{
         color:$danger;
     }
    }
 }
 
 .section-padding{
    padding:100px 0;
    @media(max-width:1368px){
        padding:5.5vw 0;
    }
 }