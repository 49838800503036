@import "../../assets/css/Components.scss";
.portfolio-box{
    &.grid-style {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
        grid-auto-rows: 200px;
        grid-auto-flow: dense;
        @media(max-width:$mobile-large){
            display:flex;
            flex-wrap: wrap;
            flex-direction: row;
        }
        .portfolio-box-item {
            position: relative;
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            overflow: hidden;
            opacity: 1;
            @media(max-width:$mobile-large){
               width:50%;
            }
            @media(max-width:$mobile){
                width:100%;
             }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.box-square {
                grid-column: span 1;
                grid-row: span 2;
                @media(max-width:$laptop){
                    grid-row: span 1;
                }
            }
            &.box-tall {
                grid-row: span 3;
                @media(max-width:$laptop){
                    grid-row: span 2;
                }
            }
        }
    }
    .video{
        width:100%;
        height:100%;
        object-fit: cover;
    }
}