@import "../../assets/css/Components.scss";
.section-title{
    @extend .d-flex;
    flex-direction: column;
    margin-bottom:2rem;
    h3{      
        @extend .text-4xl;
        text-transform: uppercase;
        margin:0;
    }
    p{
        margin:.4rem 0;
        @extend .text-lg;
        color:$gray-600;
        max-width:600px;
        width:80%;
        text-align: center;
    }
}