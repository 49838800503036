@import "../../assets/css/Components.scss";
.section-how-it-works{
    .card{ 
        @extend .d-flex;
        padding: 85px 30px 55px;
        position: relative;
        cursor: pointer;
        transition: all .3s ease;
        &:hover{
            box-shadow: rgba($black, 0.45) 0px 25px 20px -20px;
            transform: translate(0,-5px);
        }
        .card-image{
            @extend .d-flex;
            width:80px;
            height:80px;
        }
        .card-title{
            margin:1rem 0;
            font-size: 1.5rem;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 1.25px;
            text-align: center;
            @media(max-width:1368px){
                font-size:1rem;
            }
        }
        .card-text{
            p{
                margin:0;
                text-align: center;
                font-family: .75rem;
                color:$gray-600;
                @media(max-width:1368px){
                    font-size:.65rem;
                }
            }
        }
    }
}