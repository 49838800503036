@import "../../assets/css/Components.scss";
.backdrop{
    width:100%;
    height:100vh;
    position:fixed;
    z-index: 9;
    background: rgba($black, .5);
    top:0;
    left:0;
    bottom:0;
    right:0;
}
.modal-container{
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width:650px;
    width:100%;
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background-color: $white;
    z-index: 10;
    border-radius: 1rem;
    padding:2rem 2rem 1rem 2rem;
    &.active{
        display: flex;
    }
    .modal-title{
        margin:1rem 0 1.4rem 0;
        font-size:1.4rem;
    }
    .enquireNowBtn{
        margin:1.4rem auto 1rem auto;
        background-color: $primary;
        border:none;
        outline: none;
        display: block;
        text-transform: uppercase;
    }
    .icon-set{
        position:absolute;
        right:0;
        top:0;
        .fa{
            color:$gray-600;
        }
    }
}