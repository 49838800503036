@import "../../assets/css/Components.scss";
footer{
    background: $black;
    margin-top:3rem;  
    .footerLogo{
        @extend .d-flex;
        padding:2rem;
       img{
        width:150px;
        margin:0 auto;
       }
    }
    .contact-section{
        p{
            text-align: center;
            margin:0;
            color:$white;
            text-transform: uppercase;
            letter-spacing: 1.25px;
            color:$gray-500;
            font-size: 1rem;
        }
        .social{
            @extend .d-flex;
            margin-top:.4rem;
            .fa{
                color:$gray-500;
                font-size:.85rem;
            }
        }
    }  
    
}