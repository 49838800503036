@import "../../assets/css/Components.scss";
.header{
    height:100px;
    @extend .d-flex;
    justify-content: space-between;
    background-color: $black;
    border-bottom: 1px solid rgba(204, 199, 199, .35);
    position:relative;
    &.mobile{
        justify-content: center;
        height:80px;
        .logo{
            margin:0;
            width:180px;
        }
        .nav-left{
            .menu{
                .icon-set{
                    align-items: flex-start;
                }
            }
        }
        
    }
   @media(max-width:600px){
        &.mobile{
            height:56px;  
            .logo{
                width:120px;
            }          
        }
   }
    ul{
        @extend .d-flex;
        list-style: none;
        height:100%;
        margin:0;
        padding:0;
        width:calc(100% - 480px);
        @media(max-width:1300px){
            width:calc(100% - 440px);
        } 
        .top-nav{
            text-transform: uppercase;
            padding:15px 25px;
            letter-spacing: 2px;
            color:$white;
            font-size: 1rem;
            transition:color .2s ease-in-out;
            @media(max-width:1300px){
                font-size:15px;
            }
            &.active,
            &:hover{
                color:$primary;
            }
        }
    }
    .logo{
        width:200px;
        margin-left:40px;
        @media(max-width:1300px){
            width:160px;
        } 
        img{
          @extend .img;
        }
    }
    .nav-right{
        width:240px;   
        @media(max-width:1300px){
            width:200px;
        }     
        @extend .d-flex;
        justify-content: flex-end;
        .menu{
            @extend .d-flex;
            width:110px;
            height:100px;
            background-color:$primary;
            .icon-set{ 
                @extend .d-flex;
                align-items: flex-end;
                flex-direction: column;
                width:25px;
                span{ 
                    width:80%;
                    height:1px;
                    background-color: $white;
                    border-radius: 99999px;
                    +span{
                        margin-top:.4rem;
                    }
                    &.big{
                        width:100%;
                    }
                }       
            }
        }        
    }
    .nav-left{
        position: absolute;
        left:0;
        top:0;
        .menu{
            @extend .d-flex;           
            background-color:$primary;
            width:90px;
            height:80px;
            @media(max-width:600px){
                height:56px;
                width:56px;
            }
            .icon-set{ 
                @extend .d-flex;
                align-items: flex-end;
                flex-direction: column;
                width:25px;
                span{ 
                    width:80%;
                    height:1px;
                    background-color: $white;
                    border-radius: 99999px;
                    +span{
                        margin-top:.4rem;
                    }
                    &.big{
                        width:100%;
                    }
                }       
            }
        }  
    }
}