@import "../../assets//css/Components.scss";
.homeVideo{
    width: 100%;
    height: calc(100vh - 100px);
    @media(max-width:920px){
        height:50vh;
    } 
    position: relative;
    .video-wrap {
        width: 100%;
        height: inherit;
        .video{
            width: 100%;
            height: inherit;
            object-fit: cover;
        }
    }
    .video-content{
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width: 100%;
        @extend .d-flex;
        flex-direction: column;
        z-index: 2;      
        color: $white;  
        h1{
            font-size: 4rem;
            letter-spacing: 2;
            text-shadow: 1px 1px 0 #000, 2px 2px 0 #444, 3px 3px 0 #888;
            @media(max-width:920px){
                font-size:2.5rem;
            }  
            @media(max-width:540px){
                font-size:4vw;
            } 
        }
        h2{
            font-size: 2rem;
            letter-spacing: 2;
            text-shadow: 1px 1px 0 #000, 2px 2px 0 #444, 3px 3px 0 #888;
            @media(max-width:920px){
                font-size:1rem;
            }
            @media(max-width:540px){
                font-size:2vw;
            }
        }
    }
}